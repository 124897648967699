import { Dialog } from 'Shared/Component/Dialog/Dialog';
import { findOne, hasParent } from 'Shared/Helper/Dom/Dom';

const NAME = 'cancel-subscription-dialog';

const MESSAGE_HANDLERS = [
    'cancelSubscriptionClicked.all',
];

class CancelSubscriptionDialog extends Dialog {
    initialize () {
        this.bindMethodsToSelf([
            'onClose',
            'onCancelClick',
            'onCancelSubscriptionClicked',
            'onBodyClick',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        const button = findOne('button', this.element);
        button.addEventListener('click', this.onCancelClick);
    }

    onBodyClick (evt) {
        if (!hasParent(evt.target, this.element)) {
            this.element.close();
        }
    }

    onCancelClick () {
        this.element.close();
    }

    onClose () {
        document.body.removeEventListener('click', this.onBodyClick);
        document.body.classList.remove('page--dialog-open');
    }

    onCancelSubscriptionClicked () {
        this.element.showModal();
        document.body.classList.add('page--dialog-open');
        document.body.addEventListener('click', this.onBodyClick);
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': CancelSubscriptionDialog,
};
