import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, hasParent } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';

const NAME = 'masthead';

const CLASSES = {
    'menu': renderClasses(NAME, 'menu'),
    'menuOpen': renderClasses(NAME, null, ['display-menu']),
    'loginLink': renderClasses(NAME, 'link', ['login']),
};

const SELECTORS = {
    'menu': `.${CLASSES.menu}`,
    'loginLink': `.${CLASSES.loginLink}`,
};

const MESSAGE_HANDLERS = [
    'searchPageUrlChanged.all',
];

class Masthead extends ComponentController {
    initialize () {
        this.bindMethodsToSelf([
            'onMenuChange',
            'onBodyClick',
        ]);
        super.initialize();
    }

    connect () {
        super.connect();

        this.menu = findOne(SELECTORS.menu, this.element);
    }

    onMenuChange (e) {
        const isMenuOpen = this.element.classList.contains(CLASSES.menuOpen);
        const method = isMenuOpen ? 'close' : 'open';

        this[method]();

        e.stopPropagation();
        e.preventDefault();
    }

    close () {
        this.element.classList.remove(CLASSES.menuOpen);
        document.body.removeEventListener('click', this.onBodyClick);
    }

    open () {
        this.element.classList.add(CLASSES.menuOpen);
        document.body.addEventListener('click', this.onBodyClick);
    }

    onBodyClick (e) {
        const clickIsOnMenu = e.target == this.menu;

        if (clickIsOnMenu) {
            return;
        }

        const clickIsInMenu = hasParent(e.target, this.menu);

        if (clickIsInMenu) {
            return;
        }

        this.close();
    }

    onSearchPageUrlChanged (evt) {
        const loginLink = findOne(SELECTORS.loginLink, this.element);

        if (!loginLink) {
            return;
        }

        const url = new URL(loginLink.href);
        url.searchParams.set('_target_path', evt.data.data.urls.list.nl);

        loginLink.href = url.toString();
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    name: NAME,
    controller: Masthead,
};
