import { PageController } from 'Huurwoningen/Page/Page';
import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { stickySidebar } from 'Shared/Helper/Css/StickySidebar';
import { findOne } from 'Shared/Helper/Dom/Dom';
import { isMobile } from 'Shared/Helper/ViewPort/ViewPort';

const PARENT_NAME = 'page';
const NAME = 'listing-detail';

const CLASSES = {
    'actionBar': renderClasses(PARENT_NAME, 'action-bar'),
    'listingContactInfo': renderClasses(PARENT_NAME, 'listing-contact-info'),
    'sidebarElements': renderClasses(PARENT_NAME, 'sidebar-elements'),
    'listingDetailSummary': renderClasses(PARENT_NAME, 'wrapper', ['listing-detail-summary']),
    'mainInformation': renderClasses(PARENT_NAME, 'wrapper', ['main-information']),
};

const SELECTORS = {
    'actionBar': `.${CLASSES.actionBar}`,
    'listingContactInfo': `.${CLASSES.listingContactInfo}`,
    'sidebarElements': `.${CLASSES.sidebarElements}`,
    'listingDetailSummary': `.${CLASSES.listingDetailSummary}`,
    'mainInformation': `.${CLASSES.mainInformation}`,
};

class ListingDetailPage extends PageController {
    connect () {
        super.connect();

        this.initializeStickySidebar();
        this.initializeIntersectionObserver();
    }

    disconnect () {
        this.observer.disconnect();
        this.observer = null;

        super.disconnect();
    }

    initializeStickySidebar () {
        const sidebarElements = findOne(SELECTORS.sidebarElements);
        const mainInformationElement = findOne(SELECTORS.mainInformation);

        const boundaryElements = {
            'top': findOne(SELECTORS.listingDetailSummary),
            'bottom': mainInformationElement.nextElementSibling,
        };

        const options = {
            'marginTop': 32,
            'marginBottom': 64,
            'marginRight': 0,
        };

        stickySidebar(sidebarElements, boundaryElements, options);
    }

    initializeIntersectionObserver () {
        this.observer = new IntersectionObserver(this.onIntersect, {
            threshold: 0.5,
        });

        const listingContactInfo = findOne(SELECTORS.listingContactInfo);
        this.observer.observe(listingContactInfo);
    }

    onIntersect (entries) {
        if (isMobile()) {
            const listingContactInfoEntry = entries[0];
            const actionBar = findOne(SELECTORS.actionBar);

            actionBar.style.display = listingContactInfoEntry.isIntersecting ? 'none' : '';
        }
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailPage,
};
