// Turns a querystring parameter name like:
//   filters[price][min]
//
// Into a structured path like:
//   ['filters', 'price', 'min']
function parseParamName (key) {
    const path = [];
    let currentKey = '';

    key.split('').forEach(letter => {
        if (letter == '[') {
            path.push(decodeURIComponent(currentKey));
            currentKey = '';
        } else if (letter !== ']') {
            currentKey += letter;
        }
    });

    path.push(currentKey);

    return path;
}

// Turns an querystring like:
//   filters[price]=600&sort=desc
//
// Into a structure like:
//   {
//     filters: {
//       price: '600'
//     },
//     sort: 'desc'
//   }
export function queryStringToObject (queryString) {
    if (queryString == '') {
        return {};
    }

    const keyValuePairs = queryString.split('&');
    const structuredkeyValuePairs = keyValuePairs.map(pair => {
        const [name, ...valueParts] = pair.split('=');
        const value = valueParts.join('=');
        const namePath = parseParamName(name);

        return [namePath, decodeURIComponent(value)];
    });

    const output = structuredkeyValuePairs.reduce((acc, pair) => {
        const [path, value] = pair;

        const tree = path.reverse().reduce((prevValue, pathItem) => {
            let retVal;

            if (pathItem == '') {
                retVal = [prevValue];
            } else {
                retVal = {};
                retVal[pathItem] = prevValue;
            }

            return retVal;
        }, value);

        Object.keys(tree).forEach((key) => {
            acc[key] = tree[key];
        });

        return acc;
    }, {});

    return output;
}
