import { bind } from 'hyperhtml';

import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, getJSONAttribute } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'page';

const CLASSES = {
    'page': NAME,
    'pageDialogOpen': renderClasses(NAME, null, ['dialog-open']),
    'pageModalOpen': renderClasses(NAME, null, ['modal-open']),
    'modal': renderClasses(NAME, 'modal'),
    'notifications': renderClasses(NAME, 'notifications'),
};

const SELECTORS = {
    'modal': `.${CLASSES.modal}`,
    'notifications': `.${CLASSES.notifications}`,
};

const ATTRS = {
    'trackingId': 'data-tracking-id',
    'trackingReplacements': 'data-tracking-replacements',
};

const MESSAGE_HANDLERS = [
    'componentConnected.all',
    'componentInteracted.all',
    'modalClosed.all',
    'modalLoaded.all',
    'modalOpened.all',
    'notificationHidden.all',
    'notificationReceived.all',
];

class PageController extends ComponentController {
    connect () {
        super.connect();

        this.notificationCenter = findOne(SELECTORS.notifications, this.element);
        window.notify = this.notify.bind(this);
    }

    onComponentInteracted (evt) {
        const { event, id, replacements } = evt.data.data;
        this.trackEvent(event, id, replacements);
    }

    onComponentConnected (evt) {
        const { event, id, replacements } = evt.data.data;
        this.trackEvent(event, id, replacements);
    }

    onPageInteraction (evt) {
        const element = evt.currentTarget;
        const id = element.getAttribute(ATTRS.trackingId);

        if (!id) {
            return;
        }

        const event = evt.type;
        const replacements = getJSONAttribute(element, ATTRS.trackingReplacements);

        this.trackEvent(event, id, replacements);
    }

    onModalLoaded (evt) {
        const modalContainer = findOne(SELECTORS.modal, this.element);

        bind(modalContainer)`${[evt.data.data.content]}`;

        this.onModalOpened();
    }

    onModalOpened () {
        this.element.classList.add(CLASSES.pageModalOpen);
    }

    onModalClosed () {
        this.element.classList.remove(CLASSES.pageModalOpen);
    }

    onNotificationReceived (evt) {
        const [title, message, type] = evt.data.data;
        this.notify(title, message, type);
    }

    onNotificationHidden () {
        if (this.notificationCenter.children.length === 0) {
            this.notificationCenter.innerHTML = ''; // make sure it is properly empty so the :empty styling can be applied
        }
    }

    notify (title, message, type = 'info') {
        const template = document.getElementById('notification-template');
        const notification = template.content.cloneNode(true).children[0];
        const titleElement = findOne('.notification__title', notification);
        const messageElement = findOne('.notification__message', notification);

        titleElement.textContent = title;
        messageElement.innerHTML = message;
        notification.classList.add(`notification--${type}`);

        this.notificationCenter.appendChild(notification);
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }

    static getSelector () {
        return CLASSES.page;
    }

    static getDialogOpenSelector () {
        return CLASSES.pageDialogOpen;
    }
}

export { PageController };

export default {
    'name': NAME,
    'controller': PageController,
};
