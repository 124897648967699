import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findAll } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'search-list';

const CLASSES = {
    'searchItem': renderClasses(NAME, 'item', ['listing']),
    'itemLoading': renderClasses(NAME, 'item', ['loading']),
};

const SELECTORS = {
    'searchItems': `.${CLASSES.searchItem}`,
};

const MESSAGE_HANDLERS = [
    'searchPageSearchQueryChanging.all',
    'asyncListingSearch.all',
];

class SearchList extends ComponentController {
    onSearchPageSearchQueryChanging () {
        this.showLoadingState();
    }

    onAsyncListingSearch () {
        this.showLoadingState();
    }

    showLoadingState () {
        const items = findAll(SELECTORS.searchItems, this.element);

        items.forEach(item => item.classList.add(CLASSES.itemLoading));
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': SearchList,
};
