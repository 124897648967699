import { Controller } from 'stimulus';

import { MessageBus } from 'Shared/Helper/MessageBus/MessageBus';

const NAME = 'component';

const MESSAGE_HANDLERS = [];

const messageBus = new MessageBus();

/**
 * @deprecated
 */
function componentControllerFactory (dependencies) {
    return class ComponentController extends Controller {
        initialize () {
            this.messagesScope = this.data.get('scope') || 'all';
            this.i18n = this.getJSONData('i18n');
            this.tracking = this.getJSONData('tracking');

            this.messageBus = {
                postMessage: (data) => {
                    messageBus.postMessage(data, {
                        'name': this.componentName,
                        'scope': this.messagesScope,
                    });
                },
            };
        }

        connect () {
            messageBus.connect(this);
        }

        disconnect () {
            messageBus.disconnect(this);
        }

        trackEvent (type, trackingId = null, replacements = null) {
            if (!this.tracking) {
                return;
            }

            if (!this.tracking[type]) {
                return;
            }

            Object.keys(dependencies.trackers).forEach(name => {
                const service = this.tracking[type][name];

                if (!service) {
                    return;
                }

                let params = service[trackingId];

                if (!params) {
                    return;
                }

                if (replacements) {
                    Object.keys(replacements).forEach(from => {
                        const to = replacements[from];
                        params = JSON.parse(JSON.stringify(params).replace(from, to));
                    });
                }

                dependencies.trackers[name](params);
            });
        }

        getJSONData (name) {
            const data = this.data.get(name);

            if (data) {
                return JSON.parse(data);
            }

            return null;
        }

        bindMethodsToSelf (methods) {
            methods.forEach(methodName => {
                this[methodName] = this[methodName].bind(this);
            });
        }

        renderErrorNotification (status) {
            const {title, message} = this.i18n.notifications[status];

            this.handleNotificationReceived(title, message, 'error');
        }

        renderSuccessNotification (status) {
            const {title, message} = this.i18n.notifications[status];

            this.handleNotificationReceived(title, message, 'success');
        }

        handleNotificationReceived (...args) {
            this.messageBus.postMessage({
                'message': 'notificationReceived',
                'data': args,
            });
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    };
}

export { componentControllerFactory };
