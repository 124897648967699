import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { hasParent } from 'Shared/Helper/Dom/Dom';

const NAME = 'search-filters-modal';

const CLASSES = {
    'open': renderClasses('modal', null, ['open']),
    'closing': renderClasses('modal', null, ['closing']),
};

const MESSAGES = {
    'searchFiltersOpened': 'searchFiltersOpened',
    'searchFiltersClosed': 'searchFiltersClosed',
};

const MESSAGE_HANDLERS = [
    'searchFiltersButtonClicked.all',
    'searchFiltersShowResultsClicked.all',
];

function searchFiltersModalControllerFactory (dependencies) {
    return class SearchFilersModalController extends dependencies.modalController {
        initialize () {
            super.initialize();

            this.bindMethodsToSelf([
                'onTransitionEnd',
                'onBodyClick',
                'onKeyUp',
            ]);
        }

        open () {
            this.element.classList.add(CLASSES.open);

            this.messageBus.postMessage({
                'message': MESSAGES.searchFiltersOpened,
            });

            document.addEventListener('click', this.onBodyClick);
            document.addEventListener('keyup', this.onKeyUp);
        }

        close () {
            this.element.addEventListener('transitionend', this.onTransitionEnd);

            document.removeEventListener('click', this.onBodyClick);
            document.removeEventListener('keyup', this.onKeyUp);

            this.element.classList.remove(CLASSES.open);
            this.element.classList.add(CLASSES.closing);

            this.messageBus.postMessage({
                'message': MESSAGES.searchFiltersClosed,
            });
        }

        onSearchFiltersButtonClicked () {
            this.open();
        }

        onSearchFiltersShowResultsClicked () {
            this.close();
        }

        onCloseClick () {
            this.close();
        }

        onBodyClick (evt) {
            if (!hasParent(evt.target, this.element)) {
                this.close();
            }
        }

        onKeyUp (evt) {
            if (evt.key == 'Escape') {
                this.close();
            }
        }

        onTransitionEnd () {
            this.element.classList.remove(CLASSES.closing);

            this.element.removeEventListener('transitionend', this.onTransitionEnd);
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    };
}

export { searchFiltersModalControllerFactory };
