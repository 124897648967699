import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';

const NAME = 'search-filter';

const MESSAGE_HANDLERS = [
    'searchFiltersSummaryAnchorClicked.all',
];

const CLASSES = {
    'collapsed': renderClasses(NAME, null, ['collapsed']),
    'content': renderClasses(NAME, 'content'),
    'hint': renderClasses(NAME, 'hint'),
    'hintHidden': renderClasses(NAME, 'hint', ['hidden']),
};

const SELECTORS = {
    'content': `.${CLASSES.content}`,
    'hint': `.${CLASSES.hint}`,
};

function searchFilterControllerFactory (dependencies) {
    return class SearchFilterController extends dependencies.componentController {
        onCollapseToggleClick () {
            const content = findOne(SELECTORS.content, this.element);

            // if WebAnimations API is not supported, just toggle the state
            if (!('animate' in content)) {
                this.element.classList.toggle(CLASSES.collapsed);
                return;
            }

            let currentHeight, newHeight;

            if (this.element.classList.contains(CLASSES.collapsed)) {
                this.element.classList.remove(CLASSES.collapsed);

                currentHeight = 0;
                newHeight = content.offsetHeight;
            } else {
                currentHeight = content.offsetHeight;
                newHeight = 0;
            }

            const animation = content.animate([
                { 'height': `${currentHeight}px` },
                { 'height': `${newHeight}px` },
            ], {
                'duration': 300,
            });

            animation.finished.then(() => {
                if (newHeight == 0) {
                    this.element.classList.add(CLASSES.collapsed);
                }
            });
        }

        onFilterLinkClick (evt) {
            if (evt.target.nodeName == 'BUTTON') {
                evt.preventDefault();
            }
        }

        onSearchFiltersSummaryAnchorClicked (evt) {
            const evtAnchor = evt.data.data.anchor;
            const filterAnchor = this.data.get('anchor');

            if (evtAnchor === filterAnchor) {
                this.element.scrollIntoView({
                    'block': 'start',
                    'behavior': 'smooth',
                });
            }
        }

        onShowHintClick (evt) {
            evt.preventDefault();
            evt.stopPropagation();

            const hint = findOne(SELECTORS.hint, this.element);
            hint.classList.toggle(CLASSES.hintHidden);
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    };
}

export { searchFilterControllerFactory };
