import { throwError } from 'Shared/Helper/Error/Error';

export function getLocale (supportedLocales) {
    if (!('locale' in Platform)) {
        throwError('No locale found');
    }

    const locale = Platform.locale;
    const requestedLocaleIsSupported = supportedLocales.indexOf(locale) > -1;

    return requestedLocaleIsSupported ? locale : 'nl';
}
