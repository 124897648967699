export function formDataFromQueryString (queryString) {
    const urlParams = new URLSearchParams(queryString);
    const formData = new FormData();

    for (const [key, value] of urlParams) {
        formData.append(key, value);
    }

    return formData;
}
