import { findOne, hasParent } from 'Shared/Helper/Dom/Dom';
import { Dialog } from 'Shared/Component/Dialog/Dialog';

const NAME = 'mail-alert-from-listing-dialog';

class MailAlertFromListingDialog extends Dialog {
    initialize () {
        this.bindMethodsToSelf([
            'onClose',
            'onBodyClick',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        this.element.addEventListener('close', this.onClose);
        this.form = findOne('form', this.element);
        this.action = this.form.getAttribute('action');

        if (this.data.get('opened') === '1') {
            this.element.showModal();
        }
    }

    onBodyClick (evt) {
        if (!hasParent(evt.target, this.element)) {
            this.element.close();
        }
    }

    onClose () {
        document.body.removeEventListener('click', this.onBodyClick);
        document.body.classList.remove('page--dialog-open');

        const returnValue = this.element.returnValue;

        if (returnValue == 'cancel') {
            this.setCooldownCookie();
        }

        if (returnValue == 'confirm') {
            this.form.method = 'post';
            this.form.submit();
        }
    }

    setCooldownCookie () {
        const maxAge = '604800'; // 7 days
        const cookieName = this.data.get('cookie-name');

        document.cookie = `${cookieName}=1;path=/;max-age=${maxAge};secure`;
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return [];
    }
}

export default {
    'name': NAME,
    'controller': MailAlertFromListingDialog,
};
