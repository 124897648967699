import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';
import { scriptCache } from 'Shared/Helper/ScriptCache/ScriptCache';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'form';

const NAMES = {
    'recaptchaToken': 'data-form-recaptcha-token',
};

const CLASSES = {
    'greCaptchaBadge': renderClasses('grecaptcha-badge'),
};

const SELECTORS = {
    'recaptchaToken': `[${NAMES.recaptchaToken}]`,
    'buttonSubmit': 'button[type=submit]',
    'greCaptchaBadge': `.${CLASSES.greCaptchaBadge}`,
};

const MESSAGE_HANDLERS = [
    'textControlInvalid.all',
    'textControlValid.all',
    'choiceControlInvalid.all',
    'choiceControlValid.all',
    'enableSubmitButton.all',
];

class Form extends ComponentController {
    initialize () {
        this.bindMethodsToSelf([
            'initRecaptcha',
            'onTextControlInvalid',
            'onTextControlValid',
            'onChoiceControlInvalid',
            'onChoiceControlValid',
            'onEnableSubmitButton',
        ]);

        this.fieldValidity = {};

        super.initialize();
    }

    connect () {
        super.connect();

        this.action = this.element.getAttribute('action');
        this.submitButton = findOne(SELECTORS.buttonSubmit, this.element);

        this.recaptchaElement = this.getRecaptchaElement();

        const greCaptchaBadge = findOne(SELECTORS.greCaptchaBadge);

        if (greCaptchaBadge) {
            greCaptchaBadge.style.display = '';
        }

        this.disableRecaptcha = this.data.get('disable-recaptcha');

        if (this.disableRecaptcha === 'true') {
            return;
        }

        this.recaptchaSiteKey = this.data.get('recaptcha-site-key');
        this.recaptchaAction = this.data.get('recaptcha-action');
        const cache = scriptCache({
            'recaptcha': `//www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKey}`,
        });

        cache.recaptcha.onLoad(this.initRecaptcha);
    }

    disconnect () {
        const greCaptchaBadge = findOne(SELECTORS.greCaptchaBadge);

        if (greCaptchaBadge) {
            greCaptchaBadge.style.display = 'none';
        }
    }

    handleInvalidField (evt) {
        const name = evt.data.data.name;

        this.fieldValidity[name] = 'invalid';

        this.checkFormValidity();
    }

    handleValidField (evt) {
        const name = evt.data.data.name;

        this.fieldValidity[name] = 'valid';

        this.checkFormValidity();
    }

    onEnableSubmitButton () {
        this.submitButton.disabled = false;
    }

    onTextControlInvalid (evt) {
        this.handleInvalidField(evt);
    }

    onTextControlValid (evt) {
        this.handleValidField(evt);
    }

    onChoiceControlInvalid (evt) {
        this.handleInvalidField(evt);
    }

    onChoiceControlValid (evt) {
        this.handleValidField(evt);
    }

    checkFormValidity () {
        let invalidCount = 0;

        Object.keys(this.fieldValidity).forEach(fieldName => {
            if (this.fieldValidity[fieldName] == 'invalid') {
                invalidCount += 1;
            }
        });

        this.submitButton.disabled = invalidCount > 0;
    }

    onSubmit (evt) {
        evt.preventDefault();

        if (this.disableRecaptcha === 'true') {
            if (this.data.get('mode') == 'async') {
                this.sendForm();
            } else {
                this.element.submit();
            }
            return;
        }

        grecaptcha.ready(() => {
            grecaptcha.execute(this.recaptchaSiteKey, {
                'action': this.recaptchaAction,
            }).then(token => {
                this.recaptchaElement.value = token;

                if (this.data.get('mode') == 'async') {
                    this.sendForm();
                } else {
                    this.element.submit();
                }
            });
        });
    }

    async sendForm () {
        const response = await fetch(this.action, {
            'headers': {
                'X-Requested-With': 'XMLHttpRequest',
            },
            'method': 'POST',
            'body': new FormData(this.element),
        });

        const status = await response.status;

        if (status > 400) {
            this.messageBus.postMessage({
                'message': 'formResponseError',
            });

            return;
        }

        const content = await response.text();

        this.messageBus.postMessage({
            'message': 'formResponseReceived',
            'data': {
                'content': content,
            },
        });
    }

    initRecaptcha (err) {
        // Failed to load Recaptcha, show message users need to unblock it to be able to submit the form.
        if (err) {
            this.messageBus.postMessage({
                'message': 'formRecaptchaLoadError',
            });
        }
    }

    getRecaptchaElement () {
        return findOne(SELECTORS.recaptchaToken, this.element);
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }

    get componentName () {
        return NAME;
    }
}

export { Form };

export default {
    'name': NAME,
    'controller': Form,
};
