import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';
import { searchFiltersControllerFactory } from 'Shared/Component/SearchFilters/SearchFiltersControllerFactory';
import { quickAccessFiltersControllerFactory } from 'Shared/Component/QuickAccessFilters/QuickAccessFiltersControllerFactory';

const NAME = 'quick-access-filters';

const SharedSearchFiltersController = searchFiltersControllerFactory({
    'componentController': ComponentController,
});

const SharedQuickAccessFilters = quickAccessFiltersControllerFactory({
    'searchFiltersController': SharedSearchFiltersController,
});

export default {
    'name': NAME,
    'controller': SharedQuickAccessFilters,
};
