const NAME = 'search-list-sorting';

const MESSAGES = {
    'sortingChanged': 'searchListSortingChanged',
};

function searchListSortingControllerFactory (dependencies) {
    return class SearchListSorting extends dependencies.componentController {
        initialize () {
            this.bindMethodsToSelf([
                'onChange',
            ]);

            super.initialize();
        }

        onChange (evt) {
            const select = evt.currentTarget;
            const options = select.options;
            const selectedIndex = select.selectedIndex;
            const [filter, order] = options[selectedIndex].value.split('.'); // like 'price.asc'

            const sortingOptions = new FormData();
            sortingOptions.append(`sorting_options[${filter}]`, order);

            this.messageBus.postMessage({
                'message': MESSAGES.sortingChanged,
                'data': {
                    'sortingOptions': Array.from(sortingOptions),
                },
            });
        }

        get componentName () {
            return NAME;
        }
    };
}

export { searchListSortingControllerFactory };
