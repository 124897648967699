import { supportedLocales } from 'Huurwoningen/Helper/Env/Locale';
import { searchPageControllerFactory } from 'Shared/Page/Listing/Search/SearchPageControllerFactory';
import { SharedPageController } from 'Huurwoningen/Page/Page';

const NAME = 'search-page';

const SharedSearchPageController = searchPageControllerFactory({
    'pageController': SharedPageController,
    supportedLocales,
});

export default {
    'name': NAME,
    'controller': SharedSearchPageController,
};
