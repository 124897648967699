import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';
import { findOne} from "Shared/Helper/Dom/Dom";
import { renderClasses } from "Shared/Helper/Bem/Bem";

const NAME = 'rent-out';

const MESSAGE_HANDLERS = [];

const CLASSES = {
    'isAgent': renderClasses('form', 'control', ['is-agent']),
    'agentDetails': renderClasses('form', 'agent-details'),
    'agentDetailsHidden': renderClasses('form', 'agent-details', ['hidden']),
    'contactConsent': renderClasses('form', 'control', ['contact-consent']),
    'controlHidden': renderClasses('form', 'control', ['hidden']),
};

const SELECTORS = {
    'isAgentCheckbox': `.${CLASSES.isAgent} [type="checkbox"]`,
    'agentDetails': `.${CLASSES.agentDetails}`,
    'contactConsent': `.${CLASSES.contactConsent}`,
};

class RentOut extends ComponentController {
    connect () {
        this.bindMethodsToSelf([
            'toggleAgentFields',
        ]);
        this.isAgentCheckbox = findOne(SELECTORS.isAgentCheckbox, this.element);

        this.isAgentCheckbox.addEventListener('change', this.toggleAgentFields);
        this.toggleAgentFields();

        super.connect();
    }

    toggleAgentFields () {
        const agentDetails = findOne(SELECTORS.agentDetails, this.element);
        const contactConsent = findOne(SELECTORS.contactConsent, this.element);

        if (this.isAgentCheckbox.checked) {
            agentDetails.classList.remove(CLASSES.agentDetailsHidden);
            contactConsent.classList.add(CLASSES.controlHidden);
        } else {
            agentDetails.classList.add(CLASSES.agentDetailsHidden);
            contactConsent.classList.remove(CLASSES.controlHidden);
        }
    }

    onSubmit () {
        const submitButton = findOne('.form__submit-button', this.element);
        submitButton.disabled = true;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': RentOut,
};
