import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';

const NAME = 'footer';

class FooterController extends ComponentController {
    onToTopButtonClick () {
        window.scrollTo({
            'top': 0,
            'left': 0,
            'behavior': 'smooth',
        });
    }
}

export default {
    'name': NAME,
    'controller': FooterController,
};
