import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'map-listing-summary-control';

const CLASSES = {
    'mapListingSummaryControl': renderClasses(NAME),
    'mapListingSummaryControlActive': renderClasses(NAME, null, ['active']),
    'mapListingSummaryControlCluster': renderClasses(NAME, null, ['cluster']),
    'container': renderClasses(NAME, 'container'),
    'paginationCount': renderClasses(NAME, 'pagination-count'),
    'paginationButtonPrevious': renderClasses(NAME, 'pagination-button', ['previous']),
    'paginationButtonNext': renderClasses(NAME, 'pagination-button', ['next']),
    'paginationButtonDisabled': renderClasses(NAME, 'pagination-button', ['disabled']),
    'error': renderClasses(NAME, 'error'),
};

const SELECTORS = {
    'mapListingSummaryControl': `.${CLASSES.mapListingSummaryControl}`,
    'container': `.${CLASSES.container}`,
    'paginationCount': `.${CLASSES.paginationCount}`,
    'paginationButtonPrevious': `.${CLASSES.paginationButtonPrevious}`,
    'paginationButtonNext': `.${CLASSES.paginationButtonNext}`,
    'paginationButtonDisabled': `.${CLASSES.paginationButtonDisabled}`,
};

const ATTR = {
    'disabled': 'disabled',
};

const MESSAGES = {
    'hide': 'mapListingSummaryControlHide',
    'show': 'mapListingSummaryControlShow',
    'renderListingEnded': 'mapListingSummaryControlRenderEnded',
    'renderListingStarted': 'mapListingSummaryControlRenderStarted',
};

const MESSAGE_HANDLERS = [
    'searchMapFeatureClicked.all',
    'searchMapClicked.all',
];

class MapListingSummaryControl extends ComponentController {
    connect () {
        super.connect();

        this.cache = {};
        this.container = findOne(SELECTORS.container, this.element);
        this.paginationCount = findOne(SELECTORS.paginationCount, this.element);
    }

    onSearchMapClicked (evt) {
        this.close(evt);
    }

    onSearchMapFeatureClicked (evt) {
        this.render(evt);
    }

    render (evt) {
        this.listingIds = JSON.parse(evt.data.data.listing_ids);
        this.type = evt.data.data.type;
        this.index = 0;

        this.goTo(0);

        if (this.listingIds.length > 1) {
            this.element.classList.add(CLASSES.mapListingSummaryControlCluster);
        } else {
            this.element.classList.remove(CLASSES.mapListingSummaryControlCluster);
        }

        this.messageBus.postMessage({
            'message': MESSAGES.show,
        });
    }

    goPrevious () {
        this.goTo(this.index - 1);
    }

    goNext () {
        this.goTo(this.index + 1);
    }

    goTo (newIndex) {
        this.updateButtonStates(newIndex);
        this.updateCounter(newIndex);
        this.renderListingSummary(this.listingIds[newIndex]);

        this.index = newIndex;
    }

    updateCounter (index) {
        const replacements = {
            '%count%': index + 1,
            '%total%': this.listingIds.length,
        };

        this.paginationCount.textContent = this.i18n.pagination.replace(/%count%|%total%/g, match => replacements[match]);
    }

    updateButtonStates (newIndex) {
        const paginationButtonPrevious = findOne(SELECTORS.paginationButtonPrevious, this.element);
        const paginationButtonNext = findOne(SELECTORS.paginationButtonNext, this.element);

        if (paginationButtonPrevious) {
            if (newIndex == 0) {
                this.disableButton(paginationButtonPrevious);
            } else {
                this.enableButton(paginationButtonPrevious);
            }
        }

        if (paginationButtonNext) {
            if (newIndex == this.listingIds.length - 1) {
                this.disableButton(paginationButtonNext);
            } else {
                this.enableButton(paginationButtonNext);
            }
        }
    }

    enableButton (button) {
        button.classList.remove(CLASSES.paginationButtonDisabled);
        button.removeAttribute(ATTR.disabled);
    }

    disableButton (button) {
        button.classList.add(CLASSES.paginationButtonDisabled);
        button.setAttribute(ATTR.disabled, 'disabled');
    }

    close () {
        this.container.innerHTML = '';
        this.element.classList.remove(CLASSES.mapListingSummaryControlActive);

        this.messageBus.postMessage({
            'message': MESSAGES.hide,
        });
    }

    async renderListingSummary (id) {
        let template;
        let status;

        if (!this.cache.hasOwnProperty(id)) {
            this.messageBus.postMessage({
                'message': MESSAGES.renderListingStarted,
            });

            const endpoint = this.getRoute(id);

            const response = await fetch(endpoint);
            status = await response.status;
            template = await response.text();

            if (status > 400) {
                template = `<div class="${CLASSES.error}">${this.i18n.error}</div>`;
            }

            this.cache[id] = template;

            this.messageBus.postMessage({
                'message': MESSAGES.renderListingEnded,
            });
        }

        this.container.innerHTML = this.cache[id];
        this.element.classList.add(CLASSES.mapListingSummaryControlActive);
    }

    static getSelector () {
        return SELECTORS.mapListingSummaryControl;
    }

    getRoute (id) {
        return `/frontend/${Platform.locale}/listing-search-item/${id}`;
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': MapListingSummaryControl,
};
