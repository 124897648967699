import dialogPolyfill from 'dialog-polyfill';

import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

class Dialog extends ComponentController {
    initialize () {
        super.initialize();

        dialogPolyfill.registerDialog(this.element);
    }
}

export { Dialog };
