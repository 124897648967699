import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'listing-search-item';

class ListingSearchItem extends ComponentController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onClick',
        ]);
    }

    onClick () {
        const pageNumber = this.data.get('page-number');
        const index = this.data.get('index');
        const positionOnPage = this.data.get('position-on-page');
        const id = this.data.get('id');
        const agentId = this.data.get('agent-id');
        const formData = new FormData();

        formData.append('pageNumber', pageNumber);
        formData.append('index', index);
        formData.append('positionOnPage', positionOnPage);
        formData.append('id', id);
        formData.append('agentId', agentId);

        const url = '/api/track/listing-click-event';

        navigator.sendBeacon(url, formData);
    }
}

export default {
    'name': NAME,
    'controller': ListingSearchItem,
};
