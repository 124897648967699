const MESSAGES = {
    'closed': 'modalClosed',
};

function modalControllerFactory (dependencies) {
    return class Modal extends dependencies.componentController {
        onCloseClick () {
            this.element.parentElement.removeChild(this.element);

            this.messageBus.postMessage({
                'message': MESSAGES.closed,
            });
        }
    };
}

export { modalControllerFactory };
