import { MAPS_URI } from 'Shared/Helper/Env/Uris';
import { queryStringFromObject } from 'Shared/Helper/QueryString/CreateFromObject';

const getGeojsonForLocation = (() => {
    const cache = {};
    const errorCodes = [204, 400, 500];

    return async function (location) {
        // polygon endpoint doesn't support countries, so skip it
        if (location.country && !location.city) {
            return;
        }

        // in case of multiple districts ignore them all.
        if (location.district && location.district.indexOf(',') > -1) {
            location.district = undefined;
        }

        // stringify so we can use it as index of cache
        location = JSON.stringify(location);

        if (!cache[location]) {
            // make location an object again
            const queryString = queryStringFromObject(JSON.parse(location));
            const url = `${MAPS_URI}/polygon?${queryString}`;

            const response = await fetch(url);

            if (errorCodes.includes(response.status)) {
                // eslint-disable-next-line
                cache[location] = null;

                return cache[location];
            }

            const geojson = await response.json();
            // eslint-disable-next-line
            cache[location] = geojson;
        }

        return cache[location];
    };
})();

export { getGeojsonForLocation };
