import { viewport as geoViewport } from '@mapbox/geo-viewport';
import { findOne } from 'Shared/Helper/Dom/Dom';

function getZoomCenter (bounds) {
    const map = findOne('.map');
    let mapWidth = 100;
    let mapHeight = 100;

    if (map) {
        mapWidth = map.clientWidth || map.offsetWidth;
        mapHeight = map.clientHeight || map.offsetHeight;
    }

    const viewPort = geoViewport(bounds, [mapWidth, mapHeight]);

    return {
        'zoom': viewPort.zoom - 1,
        'center': viewPort.center,
    };
}

export { getZoomCenter };
