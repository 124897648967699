import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';
import { searchFiltersModalControllerFactory } from 'Shared/Component/Modal/SearchFilters/SearchFiltersControllerFactory';
import { modalControllerFactory } from 'Shared/Component/Modal/ModalControllerFactory';

const NAME = 'search-filters-modal';

const SharedModalController = modalControllerFactory({
    'componentController': ComponentController,
});

const SharedSearchFiltersModal = searchFiltersModalControllerFactory({
    'modalController': SharedModalController,
});

export default {
    'name': NAME,
    'controller': SharedSearchFiltersModal,
};
