import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'text-control';

const CONSTRAINTS = {
    'email': 'validateEmail',
    'notBlank': 'validateNotBlank',
    'minLength': 'validateMinLength',
    'maxLength': 'validateMaxLength',
    'equalsField': 'validateEqualsField',
    'regex': 'validateRegex',
};

const MESSAGES = {
    'invalid': 'textControlInvalid',
    'valid': 'textControlValid',
};

const CLASSES = {
    'hasFocus': renderClasses(NAME, null, ['has-focus']),
    'valid': renderClasses(NAME, null, ['valid']),
    'error': renderClasses(NAME, null, ['error']),
    'control': renderClasses(NAME, 'control'),
    'label': renderClasses(NAME, 'label'),
    'disabled': renderClasses(NAME, null, ['disabled']),
    'togglePassword': renderClasses(NAME, 'toggle-password'),
    'togglePasswordShown': renderClasses(NAME, 'toggle-password', ['shown']),
};

const SELECTORS = {
    'control': `.${CLASSES.control}`,
    'togglePassword': `.${CLASSES.togglePassword}`,
};

const MESSAGE_HANDLERS = [
    'disableFormFields.all',
    'enableFormFields.all',
];

class TextControl extends ComponentController {
    initialize () {
        this.bindMethodsToSelf([
            'onInput',
            'onFocus',
            'onBlur',
            'onDisableFormFields',
            'onEnableFormFields',
            'checkEmptyValue',
            'onTogglePasswordClick',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        this.hasBeenBlurred = false;

        const constraints = this.getJSONData('constraints');

        this.constraints = constraints.map(constraintData => {
            const [type, parameter] = constraintData.split(':');

            return {
                type,
                parameter,
            };
        });

        const input = findOne(SELECTORS.control, this.element);

        if (this.data.get('validate-on-connect') == '1') {
            this.validate(input.value);
        }

        this.scheduleCheckEmptyValue();
    }

    // Work around for browser autofill messing up the state
    // Autofill can fill a field after connect() has run and give it a value while leaving the styling as if it is empty
    scheduleCheckEmptyValue () {
        setTimeout(this.checkEmptyValue, 100);
    }

    checkEmptyValue () {
        const input = findOne(SELECTORS.control, this.element);

        if (input.value != '') {
            this.onFocus();
        }

        this.scheduleCheckEmptyValue();
    }

    onInput (evt) {
        if (this.hasBeenBlurred) {
            const value = evt.currentTarget.value;
            this.validate(value);
        }
    }

    onFocus () {
        this.element.classList.add(CLASSES.hasFocus);
    }

    onBlur () {
        const control = findOne(SELECTORS.control, this.element);

        this.hasBeenBlurred = true;

        if (control.value) {
            if (!this.validateNotBlank(control.value)) {
                this.element.classList.remove(CLASSES.hasFocus);
            } else {
                this.validate(control.value);
            }
        } else {
            this.validate('');
            this.element.classList.remove(CLASSES.hasFocus);
        }
    }

    onDisableFormFields (evt) {
        const { scope, values } = evt.data.data;

        if (scope.includes(this.messagesScope)) {
            const control = findOne(SELECTORS.control, this.element);
            control.disabled = true;
            control.value = values[this.messagesScope];

            this.element.classList.add(CLASSES.disabled);
            this.element.classList.remove(CLASSES.valid, CLASSES.error);
        }
    }

    onEnableFormFields (evt) {
        const { scope, values } = evt.data.data;

        if (scope.includes(this.messagesScope)) {
            const control = findOne(SELECTORS.control, this.element);
            control.disabled = false;
            control.value = values[this.messagesScope];
            this.element.classList.remove(CLASSES.disabled);
        }
    }

    onTogglePasswordClick () {
        const input = findOne(SELECTORS.control, this.element);
        const button = findOne(SELECTORS.togglePassword, this.element);

        if (input.type == 'password') {
            input.type = 'text';
            button.classList.add(CLASSES.togglePasswordShown);
        } else {
            input.type = 'password';
            button.classList.remove(CLASSES.togglePasswordShown);
        }
    }

    validate (value) {
        let count = this.constraints.length;

        // Return early so that having zero constraints do not trigger valid styling
        if (count == 0) {
            return true;
        }

        this.constraints.forEach(constraint => {
            const method = CONSTRAINTS[constraint.type];

            if (this[method](value, constraint.parameter)) {
                count--;
            }
        });

        const isValid = count == 0;

        this.element.classList.remove(CLASSES.valid, CLASSES.error);
        this.element.classList.add(isValid ? CLASSES.valid : CLASSES.error);

        const message = isValid ? 'valid' : 'invalid';
        this.messageBus.postMessage({
            'message': MESSAGES[message],
            'data': {
                'name': this.data.get('name'),
                'value': value,
            },
        });

        return isValid;
    }

    validateEmail (value) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }

    validateNotBlank (value) {
        return value != '';
    }

    validateOnlyNumbers (value) {
        const re = /^[0-9]+$'/;
        return re.test(value);
    }

    validateMinLength (value, parameter) {
        return value.length >= parseInt(parameter);
    }

    validateMaxLength (value, parameter) {
        return value.length <= parseInt(parameter);
    }

    validateEqualsField (value, parameter) {
        return findOne(`#${parameter}`).value == value;
    }

    validateRegex (value, regex) {
        // If ends with i its case insensitive, so we need to strip it off and pass it to the RegExp.
        if (regex.slice(-1) == 'i') {
            regex = regex.slice(0, -2);
            return (new RegExp(regex, 'i')).test(value);
        }

        return (new RegExp(regex)).test(value);
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': TextControl,
};
