import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';
import { findOne } from 'Shared/Helper/Dom/Dom';

const NAME = 'address-lookup';
const MESSAGE_HANDLERS = [
    'textControlValid.all',
    'textControlInvalid.all',
];

class AddressLookup extends ComponentController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'handleResponse',
        ]);
    }

    connect () {
        super.connect();

        this.postalCode = null;
        this.houseNumber = null;
        this.houseNumberAddition = null;
        this.valid = false;

        this.submitButton = findOne('.form__submit-button', this.element);
        this.message = findOne('.form__message', this.element);
    }

    onTextControlValid (evt) {
        const { name, value } = evt.data.data;

        if (!['postal_code', 'house_number', 'house_number_addition'].includes(name)) {
            return;
        }

        if (name === 'postal_code') {
            this.postalCode = value;
        }

        if (name === 'house_number') {
            this.houseNumber = value;
        }

        if (name === 'house_number_addition') {
            this.houseNumberAddition = value;
        }

        if (this.houseNumber !== null && this.postalCode !== null) {
            this.valid = true;
            this.getAddress();
        }

        this.handleValidation();
    }

    onTextControlInvalid (evt) {
        const name = evt.data.data.name;

        this.valid = false;
        this.submitButton.disabled = true;

        if (name === 'postal_code') {
            this.postalCode = null;
        }

        if (name === 'house_number') {
            this.houseNumber = null;
        }

        this.handleValidation();
    }

    onSubmit () {
        return true;
    }

    getAddress () {
        const params = {
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                'postalCode': this.postalCode,
                'houseNumber': this.houseNumber,
                'houseNumberAddition': this.houseNumberAddition,
            }),
            method: 'POST',
        };

        this.showMessage(this.i18n.searching);

        fetch('/frontend/address-lookup', params)
            .then(response => response.json())
            .then(this.handleResponse);
    }

    handleResponse (data) {
        if (data.error) {
            this.valid = false;
            this.submitButton.disabled = true;

            return this.showMessage(this.i18n.not_found);
        }

        this.valid = true;
        this.submitButton.disabled = false;

        return this.showMessage(`${data.streetName}, ${data.cityName}`);
    }

    handleValidation () {
        let label = this.i18n.default;

        if (this.postalCode === null && this.houseNumber !== null) {
            label = this.i18n.postal_code_invalid;
        }

        if (this.postalCode !== null && this.houseNumber === null) {
            label = this.i18n.house_number_invalid;
        }

        if (this.postalCode === null && this.houseNumber === null) {
            label = this.i18n.postal_code_and_house_number_invalid;
        }

        this.showMessage(label);
    }

    showMessage (label) {
        this.message.innerText = label;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': AddressLookup,
};
