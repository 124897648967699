import { findOne, hasParent, findAncestor } from 'Shared/Helper/Dom/Dom';
import { Dialog } from 'Shared/Component/Dialog/Dialog';

const NAME = 'mail-alert-from-search-dialog';

const MESSAGE_HANDLERS = [
    'mailAlertButtonClicked.all',
];

class MailAlertFromSearchDialog extends Dialog {
    initialize () {
        this.bindMethodsToSelf([
            'onClose',
            'onMailAlertButtonClicked',
            'onBodyClick',
            'onSubmitButtonClicked',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        this.element.addEventListener('close', this.onClose);
        this.form = findOne('form', this.element);
        this.action = this.form.getAttribute('action');

        this.submitButton = findOne('button[type=submit]', this.element);
        this.submitButton.addEventListener('click', this.onSubmitButtonClicked);
    }

    onSubmitButtonClicked (evt) {
        evt.preventDefault();

        const emailField = findOne('[data-text-control-name=email]', this.element);
        const frequencyField = findOne('[data-choice-control-name=frequency]', this.element);
        let emailValid = true;
        let frequencyValid = true;

        if (emailField) {
            const emailAncestorIsHidden = findAncestor('[hidden]', emailField);

            if (!emailAncestorIsHidden) {
                const emailController = stimulusApplication.getControllerForElementAndIdentifier(emailField, 'text-control');

                emailValid = emailController.validate(this.form.elements.email.value);
            }
        }

        if (frequencyField && typeof frequencyField.validate === 'function') {
            frequencyValid = frequencyField.validate(this.form.elements.frequency.value);
        }

        if (emailValid && frequencyValid) {
            this.element.returnValue = this.submitButton.value;
            this.onClose();
        }
    }

    onBodyClick (evt) {
        if (!hasParent(evt.target, this.element)) {
            this.element.close();
        }
    }

    onClose () {
        document.body.removeEventListener('click', this.onBodyClick);
        document.body.classList.remove('page--dialog-open');

        const returnValue = this.element.returnValue;

        if (returnValue === 'confirm') {
            // First change the method from dialog to post so we can submit the form the ancient way.
            this.form.method = 'post';
            this.form.submit();
        }
    }

    onMailAlertButtonClicked () {
        this.element.showModal();
        document.body.classList.add('page--dialog-open');
        document.body.addEventListener('click', this.onBodyClick);

        navigator.sendBeacon('/api/track/property-alert-creation-start');
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': MailAlertFromSearchDialog,
};
