import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findAll, findOne } from 'Shared/Helper/Dom/Dom';
import { LOCATION_TYPES } from 'Shared/Helper/SearchFilters/LocationTypes';

const NAME = 'search-filters';

const CLASSES = {
    'showResultsButton': renderClasses(NAME, 'show-results-button'),
    'showResultsButtonLoading': renderClasses(NAME, 'show-results-button', ['is-loading']),
};

const SELECTORS = {
    'showResultsButton': `.${CLASSES.showResultsButton}`,
};

const MESSAGES = {
    'locationChanged': 'searchFiltersLocationChanged',
    'submitted': 'searchFiltersSubmitted',
    'showResultsClicked': 'searchFiltersShowResultsClicked',
};

const MESSAGE_HANDLERS = [
    'autocompleteItemSelected.masthead',
    'autocompleteItemSelected.search-controls',
    'autocompleteNoResults.masthead',
    'autocompleteNoResults.search-controls',
    'searchPageSearchQueryStateChanged.all',
    'searchPageSearchQueryChanging.all',
];

function searchFiltersControllerFactory (dependencies) {
    return class SearchFilters extends dependencies.componentController {
        initialize () {
            this.bindMethodsToSelf([
                'onSubmit',
            ]);

            super.initialize();
        }

        connect () {
            super.connect();

            this.element.addEventListener('change', () => {
                this.onSubmit();
            });
        }

        onSearchPageSearchQueryChanging () {
            const showResultsButton = findOne(SELECTORS.showResultsButton, this.element);

            if (showResultsButton) {
                showResultsButton.classList.add(CLASSES.showResultsButtonLoading);
            }
        }

        onSearchPageSearchQueryStateChanged (evt) {
            const { filters } = evt.data.data;

            const formData = new FormData();

            for (const [key, value] of filters) {
                formData.append(key, value);
            }

            this.updateForm(formData);
        }

        onSubmit (evt) {
            const filters = this.serializeForm();

            this.messageBus.postMessage({
                'message': MESSAGES.submitted,
                'data': {
                    'filters': Array.from(filters),
                },
            });

            if (evt) {
                evt.preventDefault();
            }
        }

        onShowResultsClick (evt) {
            evt.preventDefault();
            this.messageBus.postMessage({
                'message': MESSAGES.showResultsClicked,
            });
        }

        onAutocompleteItemSelected (evt) {
            const { location } = evt.data.data;

            if (!location) {
                return;
            }

            const filters = this.serializeForm();

            // update location values in form
            LOCATION_TYPES.forEach(name => {
                const value = location[name];
                const key = `filters[${name}]`;

                if (value) {
                    filters.set(key, value);
                } else {
                    filters.delete(key);
                }
            });

            // empty province to prevent searching on the province page for a city in a different province
            // to mess up the search. (e.g. searching for 'Rotterdam' on '/provincie/noord-holland' would make a query for Rotterdam in Noord-Holland)
            filters.delete('filters[province]');

            this.updateForm(filters);

            this.messageBus.postMessage({
                'message': MESSAGES.locationChanged,
                'data': {
                    'filters': Array.from(filters),
                },
            });
        }

        onAutocompleteNoResults () {
            // If no results, the autocomplete value is empty, so we want to navigate to the "nederland" serp.
            const filters = this.serializeForm();

            // Empty all locations except country.
            LOCATION_TYPES.forEach(name => {
                if (name == 'country') {
                    return;
                }

                filters.delete(`filters[${name}]`);
            });

            this.updateForm(filters);

            this.messageBus.postMessage({
                'message': MESSAGES.locationChanged,
                'data': {
                    'filters': Array.from(filters),
                },
            });
        }

        serializeForm () {
            const formData = new FormData(this.element);

            const filters = {
                'filters[construction_year]': { 'multiply': false, 'add': 5 },
                'filters[price]': { 'multiply': true, 'add': false },
                'filters[contract_duration]': { 'multiply': true, 'add': false },
            };

            for (const key in filters) {
                const settings = filters[key];

                if (!formData.has(`${key}[min]`) && !formData.has(`${key}[max]`)) {
                    continue;
                }

                const maxElements = findAll(`[name="${key}[max]"] option`);

                const min = Number(formData.get(`${key}[min]`));
                const max = Number(formData.get(`${key}[max]`));

                if (max > 0 && min === 0) {
                    formData.set(`${key}[min]`, '0');
                } else if (min > 0 && max === 0) {
                    let lastValue = maxElements[maxElements.length - 1].value;
                    if (settings.multiply) {
                        lastValue = Number(lastValue) * 10;
                    }

                    if (settings.add) {
                        lastValue = Number(lastValue) + settings.add;
                    }

                    formData.set(`${key}[max]`, lastValue);
                }
            }

            // Add empty entries for all checkboxes that do not have a value.
            // Otherwise their values will not be removed if they are unchecked
            findAll('[type=checkbox]').forEach(checkbox => {
                const name = checkbox.name;

                if (!formData.has(name)) {
                    formData.append(name, '');
                }
            });

            if (formData.has('filters[nearby_city]')) {
                formData.set('filters[city]', formData.get('filters[nearby_city]'));

                [
                    'province',
                    'district',
                    'city_area',
                    'neighbourhood',
                    'street',
                    'nearby_city',
                ].forEach(key => {
                    formData.delete(`filters[${key}]`);
                });
            }

            return formData;
        }

        updateForm (formData) {
            const selects = findAll('select', this.element);
            const choiceInputs = findAll('input[type=radio], input[type=checkbox]', this.element);
            const hiddenInputs = findAll('input[type=hidden]', this.element);

            choiceInputs.forEach(input => {
                if (!formData.has(input.name)) {
                    return;
                }

                const values = formData.getAll(input.name);

                input.checked = values.includes(input.value);
            });

            [].concat(selects, hiddenInputs).forEach(input => {
                if (!formData.has(input.name)) {
                    return;
                }

                const value = formData.get(input.name);

                input.value = value;
            });
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    };
}

export { searchFiltersControllerFactory };
