import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, findAll, animatedScroll } from 'Shared/Helper/Dom/Dom';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'listing-detail-description';

const CLASSES = {
    'expanded': renderClasses(NAME, null, ['expanded']),
    'title': renderClasses(NAME, 'title'),
    'summary': renderClasses(NAME, 'summary'),
    'additional': renderClasses(NAME, 'additional'),
    'additionalCollapsed': renderClasses(NAME, 'additional', ['collapsed']),
    'readMore': renderClasses(NAME, 'read-more'),
    'button': renderClasses(NAME, 'button'),
    'mask': renderClasses(NAME, 'mask'),
    'tooltip': renderClasses(NAME, 'tooltip'),
};

const SELECTORS = {
    'title': `.${CLASSES.title}`,
    'additional': `.${CLASSES.additional}`,
    'summary': `.${CLASSES.summary}`,
    'readMore': `.${CLASSES.readMore}`,
    'button': `.${CLASSES.button}`,
    'mask': `.${CLASSES.mask}`,
    'tooltip': `.${CLASSES.tooltip}`,
};

class ListingDetailDescription extends ComponentController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onReadMoreClick',
            'fetchAdditional',
            'insertAdditional',
            'onMaskMouseEnter',
            'onMaskMouseLeave',
        ]);

        this.endpoint = this.data.get('endpoint');
        this.needsFetching = this.endpoint !== null;
    }

    connect () {
        this.initMaskBehaviours();
        this.measureDescriptionToHideReadMoreButton();
    }

    initMaskBehaviours () {
        if (!this.data.get('tooltip')) {
            return;
        }

        const masks = findAll(SELECTORS.mask, this.element);
        masks.forEach(mask => {
            mask.addEventListener('mouseenter', this.onMaskMouseEnter);
            mask.addEventListener('mouseleave', this.onMaskMouseLeave);
        });
    }

    measureDescriptionToHideReadMoreButton () {
        if (this.needsFetching || this.element.classList.contains(CLASSES.expanded)) {
            return;
        }

        const additional = findOne(SELECTORS.additional, this.element);
        const readMoreBtn = findOne(SELECTORS.button, this.element);
        const collapsedHeight = additional.offsetHeight;

        this.element.classList.add(CLASSES.expanded);

        const expandedHeight = additional.offsetHeight;

        this.element.classList.remove(CLASSES.expanded);

        if (collapsedHeight > expandedHeight) {
            this.element.classList.add(CLASSES.expanded);

            readMoreBtn.parentNode.removeChild(readMoreBtn);
        }
    }

    onReadMoreClick () {
        const additional = findOne(SELECTORS.additional, this.element);
        const readMoreBtn = findOne(SELECTORS.readMore, this.element);
        const buttonLabels = this.getJSONData('read-more-labels');

        if (this.needsFetching) {
            this.fetchAdditional();

            return;
        }

        if (this.element.classList.contains(CLASSES.expanded)) {
            animatedScroll(document.documentElement, this.scrollPositionCache);

            this.element.classList.remove(CLASSES.expanded);
            additional.classList.add(CLASSES.additionalCollapsed);

            readMoreBtn.innerText = buttonLabels.more;
        } else {
            this.scrollPositionCache = document.documentElement.scrollTop;

            this.element.classList.add(CLASSES.expanded);
            additional.classList.remove(CLASSES.additionalCollapsed);
            readMoreBtn.innerText = buttonLabels.less;

            additional.style.height = null;
        }
    }

    getTooltip () {
        let tooltip = findOne(SELECTORS.tooltip, this.element);

        if (!tooltip) {
            tooltip = document.createElement('div');
            tooltip.classList.add(CLASSES.tooltip);
            tooltip.innerHTML = this.data.get('tooltip');
            tooltip.innerHTML = tooltip.innerText;

            // just to save us from having to issue another Bol.com gift card
            const scripts = findAll('script', tooltip);
            scripts.forEach(script => {
                script.parentNode.removeChild(script);
            });

            tooltip.addEventListener('mouseenter', () => {
                clearTimeout(this.hideTooltipTimeout);
            });

            tooltip.addEventListener('mouseleave', this.onMaskMouseLeave);

            this.element.appendChild(tooltip);
        }

        return tooltip;
    }

    onMaskMouseEnter (evt) {
        const tooltip = this.getTooltip();
        const mask = evt.target;

        clearTimeout(this.hideTooltipTimeout);

        tooltip.style.display = null;

        tooltip.style.top = `${mask.offsetTop + mask.offsetHeight}px`;
        tooltip.style.left = `${mask.offsetLeft + (mask.offsetWidth / 2)}px`;
    }

    onMaskMouseLeave () {
        const tooltip = this.getTooltip();

        this.hideTooltipTimeout = setTimeout(() => {
            tooltip.style.display = 'none';
        }, 100);
    }

    fetchAdditional () {
        this.needsFetching = false;

        fetch(this.endpoint)
            .then(response => response.text())
            .then(this.insertAdditional);
    }

    insertAdditional (html) {
        const additional = findOne(SELECTORS.additional, this.element);
        const readMoreBtn = findOne(SELECTORS.readMore, this.element);
        const buttonLabels = this.getJSONData('read-more-labels');

        this.scrollPositionCache = document.documentElement.scrollTop;

        additional.innerHTML = html;

        this.element.classList.add(CLASSES.expanded);
        readMoreBtn.innerText = buttonLabels.less;
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailDescription,
};
