function geocode (query) {
    const urlParams = new URLSearchParams();

    // Some weird edge case with urls like: /koopwoningen/nederland/wijk-nederland
    query = query == 'nederland, undefined' ? 'nederland' : query;

    urlParams.append('key', Platform.keys.location_iq);
    urlParams.append('q', query);
    urlParams.append('format', 'json');
    urlParams.append('countrycodes', 'nl');

    // LocationIQ returns as the first result the entire kingdom with the caribean islands as well
    // Because we want the mainland we request two results and take the second one
    if (query === 'nederland') {
        urlParams.append('limit', '2');
    } else {
        urlParams.append('limit', '1');
    }

    const queryString = urlParams.toString();

    const url = `https://eu1.locationiq.com/v1/search?${queryString}`;

    return fetch(url)
        .then(response => response.json())
        .then(result => {
            let resultIndex = 0;

            if (query === 'nederland') {
                resultIndex = 1;
            }

            const feature = result[resultIndex];
            const output = {};

            if ('boundingbox' in feature) {
                output.bounds = {
                    'topLeft': {
                        'lat': parseFloat(feature.boundingbox[1]), // max lat
                        'lng': parseFloat(feature.boundingbox[2]), // min lng
                    },
                    'bottomRight': {
                        'lat': parseFloat(feature.boundingbox[0]), // min lat
                        'lng': parseFloat(feature.boundingbox[3]), // max lng
                    },
                };
            }

            if ('lat' in feature && 'lon' in feature) {
                output.center = {
                    'lat': parseFloat(feature.lat),
                    'lng': parseFloat(feature.lon),
                };
            }

            return output;
        });
}

export { geocode };
