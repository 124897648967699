import { loadingIndicatorControllerFactory } from 'Shared/Component/LoadingIndicator/LoadingIndicatorControllerFactory';
import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';

const NAME = 'loading-indicator';

const SharedLoadingIndicatorController = loadingIndicatorControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedLoadingIndicatorController,
};
