import { getGeojsonForLocation } from 'Shared/Helper/GeoJson/GetGeoJsonForLocation';
import { getZoomCenter } from 'Shared/Helper/Mapbox/ZoomCenter';
import { geocode } from 'Shared/Helper/LocationIq/GeoCode';
import { LOCATION_TYPES } from 'Shared/Helper/SearchFilters/LocationTypes';
import { precisionRound } from 'Shared/Helper/Math/PrecisionRound';
import bbox from '@turf/bbox';

export class SearchQuery {
    constructor () {
        this.resetState();
    }

    resetState (state = {}) {
        const { filters, viewOptions, mapOptions, sortingOptions } = state;

        this.filters = new FormData();
        this.viewOptions = new FormData();
        this.sortingOptions = new FormData();

        if (filters) {
            this.setFilters(filters);
        }

        if (viewOptions) {
            this.setViewOptions(viewOptions);
        }

        if (sortingOptions) {
            this.setSortingOptions(sortingOptions);
        }

        this.mapOptions = mapOptions || {};
    }

    getState () {
        return {
            'filters': Array.from(this.filters),
            'sortingOptions': Array.from(this.sortingOptions),
            'viewOptions': Array.from(this.viewOptions),
            'mapOptions': this.mapOptions,
        };
    }

    setFilters (filters) {
        for (const [key] of filters) {
            this.filters.delete(key);
        }

        for (const [key, value] of filters) {
            this.filters.append(key, value);
        }
    }

    setViewOptions (viewOptions) {
        for (const [key] of viewOptions) {
            this.viewOptions.delete(key);
        }

        for (const [key, value] of viewOptions) {
            this.viewOptions.append(key, value);
        }
    }

    setSortingOptions (sortingOptions) {
        const newSortingOptions = new FormData();

        for (const [key, value] of sortingOptions) {
            newSortingOptions.append(key, value);
        }

        this.sortingOptions = newSortingOptions;
    }

    resetPage () {
        this.viewOptions.delete('view_options[page]');
    }

    setPage (page) {
        this.viewOptions.set('view_options[page]', page);
    }

    resetSorting () {
        this.sortingOptions = new FormData();
    }

    async setMapOptions (mapOptions = {}) {
        let { zoom, center } = mapOptions;
        let geojson;

        // get location outline from locationDB
        this.mapOptions.geojson = geojson = await getGeojsonForLocation(this.location);

        // if zoom & center are present here, they are provided via the URL
        if (zoom && center) {
            center = center.split(',');
            zoom = parseFloat(zoom);

            this.setZoomCenter(zoom, center);

            return;
        }

        // determine the zoom & center via the bounding box from the location outline
        if (geojson && geojson.type == 'FeatureCollection') {
            const boundingBox = bbox(geojson);
            const zoomCenter = getZoomCenter(boundingBox);

            this.setZoomCenter(zoomCenter.zoom, zoomCenter.center);

            return;
        }

        // query mapbox for boundingbox and center
        geojson = await geocode(this.name);
        const bounds = geojson.bounds;
        center = geojson.center;

        // streets don't have bounds, only a center
        if (bounds) {
            const boundingBox = [bounds.topLeft.lng, bounds.bottomRight.lat, bounds.bottomRight.lng, bounds.topLeft.lat];
            const zoomCenter = getZoomCenter(boundingBox);

            this.setZoomCenter(zoomCenter.zoom, zoomCenter.center);

            return;
        }

        // zoom level can't be determined from bounding box, use default value;
        this.setZoomCenter(15, [center.lng, center.lat]);
    }

    setZoomCenter (zoom, center) {
        this.mapOptions.zoom = precisionRound(zoom, 5);
        this.mapOptions.center = [precisionRound(center[0], 5), precisionRound(center[1], 5)];
    }

    get name () {
        switch (this.type) {
            case 'street':
                return `${this.location.street}, ${this.location.city}`;
            case 'district':
                return `${this.location.district}, ${this.location.city}`;
            case 'neighbourhood':
                return `${this.location.neighbourhood}, ${this.location.city}`;
            case 'city':
                return this.location.city;
            case 'city_area':
                return this.location.city_area;
            case 'province':
                return this.location.province;
            default:
                return this.location.country;
        }
    }

    get type () {
        if (typeof this.location.street != 'undefined') {
            return 'street';
        }

        if (typeof this.location.district != 'undefined' && this.location.district.indexOf(',') == -1) {
            return 'district';
        }

        if (typeof this.location.neighbourhood != 'undefined') {
            return 'neighbourhood';
        }

        if (typeof this.location.city != 'undefined') {
            return 'city';
        }

        if (typeof this.location.city_area != 'undefined') {
            return 'city_area';
        }

        if (typeof this.location.province != 'undefined') {
            return 'province';
        }

        return 'country';
    }

    get location () {
        const location = {};

        LOCATION_TYPES.forEach(locationType => {
            const filterValue = this.filters.get(`filters[${locationType}]`);

            if (filterValue) {
                location[locationType] = filterValue;
            }
        });

        return location;
    }
}
