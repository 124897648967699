import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'listing-detail-action-bar';

const MESSAGES = {
    'showExternalDialogClick': 'showExternalDialogClick',
};

class ListingDetailActionBar extends ComponentController {
    initialize () {
        this.bindMethodsToSelf([
            'onShowExternalDialogClick',
        ]);

        super.initialize();
    }

    onShowExternalDialogClick (evt) {
        this.messageBus.postMessage({
            'message': MESSAGES.showExternalDialogClick,
        });

        this.trackCtaClick(evt);
    }

    trackCtaClick (evt) {
        const link = evt.target;

        const notifyUrl = link.getAttribute('data-notify-url');
        const listingId = link.getAttribute('data-listing-id');
        const agentId = link.getAttribute('data-agent-id');

        if (notifyUrl && listingId) {
            const formData = new FormData();
            formData.append('listingId', listingId);

            if (agentId) {
                formData.append('agentId', agentId);
            }

            navigator.sendBeacon(notifyUrl, formData);
        }
    }

    storeUnfinishedClickout (evt) {
        const link = evt.target;

        const notifyUrl = link.getAttribute('data-unfinished-clickout-url');
        const listingId = link.getAttribute('data-listing-id');
        const userId = link.getAttribute('data-user-id');

        if (notifyUrl && listingId && userId) {
            const formData = new FormData();
            formData.append('listingId', listingId);
            formData.append('userId', userId);

            navigator.sendBeacon(notifyUrl, formData);
        }
    }

    get componentName () {
        return NAME;
    }
}

export default {
    'name': NAME,
    'controller': ListingDetailActionBar,
};
