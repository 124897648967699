//  Takes a (nested) object:
//    {
//      filters: {
//        price: 600
//      },
//      sort: 'desc'
//    }
//
//  and returns a flattend path-value array:
//    [
//      [['filters', 'price'], 600],
//      [['sort'], 'desc']
//    ]
//
function mapKeyValuePairs (params, ancestorsPath = []) {
    let pairs = [];

    Object.keys(params).forEach(key => {
        const path = ancestorsPath.concat([key]);
        const value = params[key];

        if (value && typeof value == 'object') {
            let objectPairs;

            if ('length' in value) {
                const itemPath = path.concat(['']);

                objectPairs = value.map(item => {
                    return [itemPath, item];
                });
            } else {
                objectPairs = mapKeyValuePairs(value, path);
            }

            pairs = pairs.concat(objectPairs);
        } else {
            pairs.push([path, value]);
        }
    });

    return pairs;
}

//  Takes a flattend path-value array:
//    [
//      [['filters', 'price'], 600],
//      [['sort'], 'desc']
//    ]
//
//  and returns a querystring:
//    filters[price]=600&sort=desc
function makeQueryString (keyValuePairs) {
    return keyValuePairs
        .map(pair => {
            const [path, value] = pair;
            const flattendPath = path.reduce((current, item) => {
                return current == '' ? item : `${current}[${item}]`;
            }, '');

            return `${encodeURIComponent(flattendPath)}=${encodeURIComponent(value)}`;
        })
        .join('&');
}

export function queryStringFromObject (params) {
    const pairs = mapKeyValuePairs(params);
    const queryString = makeQueryString(pairs);

    return queryString;
}
