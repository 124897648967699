import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findAncestor, findAll } from 'Shared/Helper/Dom/Dom';
import { displayLightBox } from 'Shared/Helper/Lightbox/LightboxManager';

const NAME = 'media-mosaic';

const CLASSES = {
    'item': renderClasses(NAME, 'item'),
};

const SELECTORS = {
    'item': `.${CLASSES.item}`,
};

const ATTRS = {
    'carrouselIndex': 'data-carrousel-index',
};

function mediaMosaicControllerFactory (dependencies) {
    return class MediaMosaic extends dependencies.componentController {
        onItemClick (evt) {
            let itemNode;

            if (evt.target.classList.contains(CLASSES.item)) {
                itemNode = evt.target;
            } else {
                itemNode = findAncestor(SELECTORS.item, evt.target);
            }

            const allItems = findAll(SELECTORS.item, this.element);
            const itemIndex = allItems.indexOf(itemNode);

            let url = this.data.get('lightbox-url');
            url = `${url}?autoplay=${itemIndex}`;

            displayLightBox(url, html => {
                return html.replace(`${ATTRS.carrouselIndex}="0"`, `${ATTRS.carrouselIndex}="${itemIndex}"`);
            });
        }

        onMediaGridButtonClick () {
            const url = this.data.get('media-grid-url');

            displayLightBox(url);
        }
    };
}

export { mediaMosaicControllerFactory };
