import { hyper } from 'hyperhtml';

import { renderClasses } from 'Shared/Helper/Bem/Bem';

const NAME = 'loading-indicator';

const CLASSES = {
    'loadingIndicator': renderClasses(NAME),
    'loadingIndicatorContainer': renderClasses(NAME, 'container'),
    'loadingIndicatorHide': renderClasses(NAME, null, ['hide']),
    'loadingIndicatorPath': renderClasses(NAME, 'path'),
};

const MESSAGE_HANDLERS = [
    'mapListingSummaryControlRenderEnded.all',
    'mapListingSummaryControlRenderStarted.all',
];

function loadingIndicatorControllerFactory (dependencies) {
    return class LoadingIndicator extends dependencies.componentController {
        initialize () {
            this.bindMethodsToSelf(['hide']);
        }

        connect () {
            super.connect();

            this.element.addEventListener('transitionend', this.hide);

            this.hide();
        }

        onMapListingSummaryControlRenderStarted () {
            this.show();
        }

        onMapListingSummaryControlRenderEnded () {
            this.transitionOut();
        }

        show () {
            this.element.style.display = 'block';

            requestAnimationFrame(() => {
                this.element.classList.remove(CLASSES.loadingIndicatorHide);
            });
        }

        transitionOut () {
            this.element.classList.add(CLASSES.loadingIndicatorHide);
        }

        hide () {
            if (this.element.classList.contains(CLASSES.loadingIndicatorHide)) {
                this.element.style.display = 'none';
            }
        }

        static renderTemplate () {
            const componentClasses = `${CLASSES.loadingIndicator} ${CLASSES.loadingIndicatorHide}`;

            return hyper`
                <div data-controller="${NAME}" class="${componentClasses}" style="display: none">
                    <svg class="${CLASSES.loadingIndicatorContainer}" viewBox="25 25 50 50">
                        <circle class="${CLASSES.loadingIndicatorPath}" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                </div>
            `;
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    };
}

export { loadingIndicatorControllerFactory };
