import { componentControllerFactory } from 'Shared/Helper/Stimulus/ComponentControllerFactory';

const dependencies = {
    'trackers': {},
};

/**
 * @deprecated
 */
const ComponentController = componentControllerFactory(dependencies);

export { ComponentController };
