import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';
import { mediaMosaicControllerFactory} from 'Shared/Component/MediaMosaic/MediaMosaicControllerFactory';

const NAME = 'media-mosaic';

const SharedMediaMosaicController = mediaMosaicControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedMediaMosaicController,
};
