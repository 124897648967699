import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';
import { searchFilterControllerFactory } from 'Shared/Component/SearchFilter/SearchFilterControllerFactory';

const NAME = 'search-filter';

const SharedSearchFilterController = searchFilterControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedSearchFilterController,
};
