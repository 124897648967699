import { isDesktop } from 'Shared/Helper/ViewPort/ViewPort';
import { offsetRelativeToAncestor } from 'Shared/Helper/Dom/Dom';

function createRafLoop (callback) {
    let raf = null;
    let cancelRafTimeout = null;

    function rafCallback () {
        callback();

        return requestAnimationFrame(rafCallback);
    }

    return function rafLoop () {
        if (!raf) {
            raf = rafCallback();
        }

        clearTimeout(cancelRafTimeout);
        cancelRafTimeout = setTimeout(() => {
            cancelAnimationFrame(raf);
            raf = null;
        }, 250);
    };
}

function getMeasurements (element, boundaryElements) {
    const elementParent = element.parentNode;
    const elementParentOffsets = offsetRelativeToAncestor(elementParent, document.body);
    const boundaryTopOffsets = offsetRelativeToAncestor(boundaryElements.top, document.body);
    const boundaryBottomOffsets = offsetRelativeToAncestor(boundaryElements.bottom, document.body);

    return {
        'elementParent': {
            'top': elementParentOffsets.top,
            'right': document.body.offsetWidth - (elementParentOffsets.left + elementParent.offsetWidth),
        },
        'topBoundary': {
            'bottom': boundaryTopOffsets.top + boundaryElements.top.offsetHeight,
        },
        'bottomBoundary': {
            'top': boundaryBottomOffsets.top,
        },
    };
}

function updatePositioning (element, boundaryElements, options) {
    let position = null;
    let top = null;
    let right = null;
    let bottom = null;

    if (isDesktop()) {
        const scrollPosition = parseInt(window.pageYOffset);
        const measurements = getMeasurements(element, boundaryElements);

        const scrolledFarEnoughToOverlapTopBoundary = scrollPosition > measurements.topBoundary.bottom;
        const scrolledFarEhoughToOverlapBottomBoundary = measurements.bottomBoundary.top - scrollPosition < element.clientHeight + options.marginTop + options.marginBottom;

        if (scrolledFarEhoughToOverlapBottomBoundary) {
            position = 'absolute';
            bottom = `${options.marginBottom}px`;
            right = `${options.marginRight}px`;
            top = 'auto';
        } else if (scrolledFarEnoughToOverlapTopBoundary) {
            position = 'fixed';
            top = `${options.marginTop}px`;
            right = `${measurements.elementParent.right + options.marginRight}px`;
            bottom = null;
        }
    }

    element.style.position = position;
    element.style.top = top;
    element.style.right = right;
    element.style.bottom = bottom;
}

function stickySidebar (element, boundaryElements, options) {
    const rafLoop = createRafLoop(() => {
        updatePositioning(element, boundaryElements, options);
    });

    window.addEventListener('scroll', rafLoop);
    window.addEventListener('resize', rafLoop);
}

export { stickySidebar };
