import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'manage-subscription-page';

class ManageSubscription extends ComponentController {
    onCancelSubscriptionClick (evt) {
        this.messageBus.postMessage({
            'message': 'cancelSubscriptionClicked',
        });

        evt.preventDefault();
    }
}

export default {
    'name': NAME,
    'controller': ManageSubscription,
};
