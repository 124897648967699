const NAME = 'quick-access-filters';

const MESSAGE_HANDLERS = [
    'searchPageSearchQueryStateChanged.all',
];

function quickAccessFiltersControllerFactory (dependencies) {
    return class QuickAccessFilters extends dependencies.searchFiltersController {
        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }

        get componentName () {
            return NAME;
        }
    };
}

export { quickAccessFiltersControllerFactory };
