import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne } from 'Shared/Helper/Dom/Dom';

const NAME = 'search-filters-group';

const MESSAGES = {
    'active': 'searchFiltersGroupActive',
    'inactive': 'searchFiltersGroupInactive',
    'toggleClicked': 'searchFiltersGroupToggleClicked',
    'clearClicked': 'searchFiltersGroupClearClicked',
};

const MESSAGE_HANDLERS = [
    'searchFiltersGroupToggleClicked.all',
    'searchFiltersSubmitted.all',
];

const CLASSES = {
    'filters': renderClasses(NAME, 'filters'),
    'filtersVisible': renderClasses(NAME, 'filters', ['visible']),
    'animateFilters': renderClasses(NAME, 'filters', ['animate']),
    'active': renderClasses(NAME, null, ['active']),
    'toggle': renderClasses(NAME, 'button'),
};

const SELECTORS = {
    'filters': `.${CLASSES.filters}`,
    'toggle': `.${CLASSES.toggle}`,
};

const ATTRS = {
    'searchFiltersGroupName': 'data-search-filters-group-name',
};

const keepOpenOnChange = new Set();

function searchFiltersGroupControllerFactory (dependencies) {
    return class SearchFiltersGroup extends dependencies.componentController {
        initialize () {
            super.initialize();

            this.bindMethodsToSelf([
                'hideIfEventIsNotOnElement',
                'kickOffResizeRaf',
                'scheduleResizeRaf',
                'positionFilters',
            ]);

            document.body.addEventListener('click', this.hideIfEventIsNotOnElement);
            document.body.addEventListener('keyup', (evt) => {
                if (evt.key == 'Escape') {
                    this.hideIfEventIsNotOnElement(evt);
                }
            });

            this.isOpen = false;

            if (keepOpenOnChange.has(this.data.get('name'))) {
                this.show(false);
            }
        }

        onSearchFiltersGroupToggleClicked (evt) {
            const { searchFiltersGroupName } = evt.data.data;
            const filtersNode = findOne(SELECTORS.filters, this.element);

            const isAlreadyVisible = filtersNode.classList.contains(CLASSES.filtersVisible);
            const shouldShow = searchFiltersGroupName == this.data.get('name');

            if (shouldShow && !isAlreadyVisible) {
                this.show();
            } else if (this.isOpen) {
                this.hide();
            }
        }

        onSearchFiltersSubmitted () {
            if (!keepOpenOnChange.has(this.data.get('name'))) {
                this.hide();
            }
        }

        onToggleClick () {
            // sends a mesage out letting everybody know this is toggled so every SearchFiltersGroup
            // (including the one who send the message) can use the same logic to open or close
            this.messageBus.postMessage({
                'message': MESSAGES.toggleClicked,
                'data': {
                    'searchFiltersGroupName': this.data.get('name'),
                },
            });
        }

        onClearClick () {
            this.messageBus.postMessage({
                'message': MESSAGES.clearClicked,
                'data': {
                    'searchFiltersGroupName': this.data.get('name'),
                },
            });
        }

        onCloseClick () {
            this.hide();
        }

        hideIfEventIsNotOnElement (evt) {
            const onElement = evt.target == this.element;
            if (onElement) {
                return;
            }

            // This used to be `hasParent(evt.target, this.element)` but for some reason this fails so
            // instead we check if each ancestor has the same `data-search-filters-group-name` value as `this.element`
            let parent = evt.target.parentNode;
            while (parent && ('getAttribute' in parent)) {
                if (parent.getAttribute(ATTRS.searchFiltersGroupName) == this.data.get('name')) {
                    return;
                }

                parent = parent.parentNode;
            }

            if (this.isOpen) {
                this.hide();
            }
        }

        show (animate = true) {
            // Show the filters but use visbility:hidden so we can take measurements without painting them to screen
            const filtersNode = findOne(SELECTORS.filters, this.element);

            if (animate) {
                filtersNode.classList.add(CLASSES.animateFilters);
            }

            filtersNode.classList.add(CLASSES.filtersVisible);

            this.positionFilters();

            window.addEventListener('resize', this.kickOffResizeRaf);

            this.isOpen = true;

            this.element.classList.add(CLASSES.active);

            this.messageBus.postMessage({
                'message': MESSAGES.active,
                'data': {
                    'name': this.data.get('name'),
                },
            });

            if (this.data.get('keep-open-on-change') == 'true') {
                keepOpenOnChange.add(this.data.get('name'));
            }
        }

        kickOffResizeRaf () {
            if (!this.resizeRaf) {
                this.resizeRaf = requestAnimationFrame(this.scheduleResizeRaf);
            }

            clearTimeout(this.resizeRafTimeout);
            this.resizeRafTimeout = setTimeout(() => {
                cancelAnimationFrame(this.resizeRaf);
                this.resizeRaf = null;
            }, 250);
        }

        scheduleResizeRaf () {
            this.positionFilters();
            this.resizeRaf = requestAnimationFrame(this.scheduleResizeRaf);
        }

        positionFilters () {
            const filtersNode = findOne(SELECTORS.filters, this.element);
            const toggleButton = findOne(SELECTORS.toggle, this.element);

            let marginLeft = toggleButton.clientWidth / -2;
            filtersNode.style.marginLeft = `${marginLeft}px`;

            // measure the positioning in relation to the window
            const { left, right } = filtersNode.getBoundingClientRect();

            // correct for any overhang
            if (left < 0) {
                marginLeft = marginLeft - left;
            } else if (right > window.innerWidth) {
                marginLeft = marginLeft - (right - window.innerWidth);
            }

            // Reposition and paint on screen
            filtersNode.style.marginLeft = `${marginLeft}px`;
        }

        hide () {
            const filtersNode = findOne(SELECTORS.filters, this.element);
            filtersNode.classList.remove(CLASSES.filtersVisible, CLASSES.animateFilters);

            this.isOpen = false;

            window.removeEventListener('resize', this.kickOffResizeRaf);

            this.element.classList.remove(CLASSES.active);

            this.messageBus.postMessage({
                'message': MESSAGES.inactive,
                'data': {
                    'name': this.data.get('name'),
                },
            });

            if (keepOpenOnChange.has(this.data.get('name'))) {
                keepOpenOnChange.delete(this.data.get('name'));
            }
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    };
}

export { searchFiltersGroupControllerFactory };
