export function pruneFormData (formData) {
    const prunedFormData = new FormData();

    for (const [key, value] of formData) {
        if (value) {
            prunedFormData.append(key, value);
        }
    }

    return prunedFormData;
}
